import axios from '@/libs/axios';
import { Goodsinfo,Inquiry,Cart} from './variable.js';
//产品信息
export const goodsinfo = (data) => {
    return axios.request({
        url: Goodsinfo.info,
        data: data,
        method: 'post'
    });
};
//询价
export const inquiry = (data) => {
    return axios.request({
        url: Inquiry.inquiry,
        data: data,
        method: 'post'
    });
};
//添加选样车
export const add = (data) => {
    return axios.request({
        url: Cart.add,
        data: data,
        method: 'post'
    });
};
