<template>
  <Main class="background" :back="true">
    <Header></Header>
    <div v-loading="loading" :element-loading-text="loadingtime">
      <Breadcrumb :to="to"></Breadcrumb >
      <div class="swiperinfo">
        <div>
          <div class="swiperleft">
            <Swiper
              :picture="picture"
              :detail="detail"
              v-model="loading"
            ></Swiper>
          </div>
          <div class="rightinfo" >
            <div class="title-text">
              {{
                this.getLanguageData({
                  zh: this.infoList.name,
                  en: this.infoList.name_en,
                })
              }}
            </div>
            <ul class="content-text">
              <li>
                {{ $t("goodsinfo.article_number") }}
                <span class="righttextcolor">
                  : {{ infoList.article_number }}</span
                >
              </li>
              <li v-if="infoList.packing">
                {{ $t("goodsinfo.packing") }}
                <span class="righttextcolor">
                  :
                  {{
                    getLanguageData({
                      zh: infoList.packing,
                      en: infoList.packing_en,
                    })
                  }}</span
                >
              </li>
              <li v-if="infoList.length">
                {{ $t("goodsinfo.info_size") }} :
                <span class="righttextcolor">
                  {{ productSpecification }} cm</span
                >
              </li>
              <li v-if="infoList.pack_length">
                {{ $t("goodsinfo.packing_size") }}:
                <span class="righttextcolor">{{ specification }} cm</span>
              </li>
              <li v-if="infoList.carton_length">
                {{ $t("goodsinfo.outer_box") }} :
                <span class="righttextcolor">{{ outerBox }} cm</span>
              </li>
              <li v-if="infoList.carton_quantity">
                {{ $t("goodsinfo.carton_quantity") }} :
                <span class="righttextcolor">
                  {{ infoList.carton_quantity }}</span
                >
              </li>
              <li v-if="infoList.gross_weight">
                {{ $t("goodsinfo.gross_weight") }}
                <span class="righttextcolor"> : {{ weight }} KGS</span>
              </li>
              <li v-if="infoList.carton_cubic_meter">
                {{ $t("goodsinfo.carton_cubic_meter") }} :
                <span class="righttextcolor">
                  {{ infoList.carton_cubic_meter }} cbm</span
                >
              </li>
              <li v-if="infoList.carton_cubic_feet">
                {{ $t("goodsinfo.carton_cubic_feet") }} :
                <span class="righttextcolor">
                  {{ infoList.carton_cubic_feet }} cuft</span
                >
              </li>
              <li v-if="infoList.inbox_count">
                {{ $t("goodsinfo.inbox_count") }} :
                <span class="righttextcolor"
                  >{{ infoList.inbox_count }} cbm</span
                >
              </li>
              <li v-if="infoList.certificate" :title="infoList.certificate" class="ellipsis">
                {{ $t("goodsinfo.certificate") }} :
                <span class="righttextcolor">{{ infoList.certificate }}</span>
              </li>
            </ul>
            <div class="button-item">
              <div v-if="!cartid" class="buttonclassleft" @click="gocart">
                {{ $t("goodsinfo.Shopping_cart") }}
              </div>
              <div  v-else @click="gocart" class="buttonshowleft">
                {{$t("goodsinfo.deletecart")}}
              </div>
              <div
                class="buttonclassright"
                @click="findstate"
                plain
                type="danger"
              >
                {{ $t("goodsinfo.enquiry") }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="wrappertext">
        <div class="wrapper-img">
          <el-image
            class="wrapper-img-size"
            :src="exhibitor.logo"
            fit="contain"
            style="width:100%;height:100%"
            @click.native="jump('/exhibitor', { id: exhibitorid })"
            :title="getLanguageData({
              zh: exhibitor.company_name,
              en: exhibitor.company_en_name,
            })"
          ></el-image>
        </div>
        <span class="toyname" @click="jump('/exhibitor', { id: exhibitorid })"
          >{{
            getLanguageData({
              zh: exhibitor.company_name,
              en: exhibitor.company_en_name,
            })
          }}
        </span>
        <ul class="toy-phone">
          <li>
            {{ $t("goodsinfo.contact") }}：{{
              getLanguageData({
                zh: exhibitor.contact,
                en: exhibitor.contact_en,
              })
            }}
          </li>
          <li>{{ $t("goodsinfo.mobile") }}：{{ exhibitor.mobile }}</li>
          <li>{{ $t("goodsinfo.telephone") }}：{{ exhibitor.telephone }}</li>
          <li>
            {{ $t("goodsinfo.boothinfo") }}：
            <span class="info-color">{{ exhibitor.booth }}</span>
          </li>
        </ul>
      </div>
      <div class="hine-info" v-if="detailimg">
        <div class="hine">{{ $t("goodsinfo.productdetails") }}</div>
      </div>
      <div class="content" v-if="detailimg">
        <div class="footer-img">
          <div class="img-size" v-html="detailimg"></div>
        </div>
      </div>
    </div>
    <Dialog
      v-model="dialogVisible"
      :infoList="infoList"
      :exhibitor="exhibitor"
    ></Dialog>
  </Main>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb"
import { list, del } from '@/api/cart.js';
import Dialog from "./dialog";
import Header from "@/components/layout2/layout2-header.vue";
import { goodsinfo, add } from "@/api/goodsinfo.js";
import { MessageBox } from "element-ui";
import Swiper from "./swiper";
import { getLanguageData, isLogin, jump } from "@/libs/tools.js";
import Main from "@/components/layout2/layout2-main.vue";
export default {
  components: {
    Main,
    Swiper,
    Header,
    Dialog,
     Breadcrumb
  },
  data() {
    let that=this
    return {
      id: 0, //页面参数ID
      exhibitorid: 0,
      dialogVisible: false,
      loading: true,
      infoList: {},
      exhibitor: {},
      picture: [],
      detail: {},
      cartid:0,
      to: [
        {
          name: this.$t("common.title9"),
          path: '/',
          query:{}
        },
          {
          name: "",
          path: '/exhibitor',
          query:{}
        }, 
        {
          name: "",
          query:{}
        },
        
      ],
    };
  },
  computed: {
    titleinfo(){
      return  this.getLanguageData({
                  zh: this.infoList.name,
                  en: this.infoList.name_en,
                })
    },
    exhibitorname(){
  return this.getLanguageData({
                  zh: this.exhibitor.company_name,
                  en: this.exhibitor.company_en_name,
                })
    },
    loadingtime() {
      return this.$t("goodsinfo.loading");
    },
    detailimg() {
      return this.getLanguageData({
        zh: this.detail.detail,
        en: this.detail.detail_en,
      });
    },
    specification() {
      return (
        this.infoList.pack_length +
        "*" +
        this.infoList.pack_width +
        "*" +
        this.infoList.pack_height
      );
    },
    productSpecification() {
      return (
        this.infoList.length +
        "*" +
        this.infoList.width +
        "*" +
        this.infoList.height
      );
    },
    outerBox() {
      return (
        this.infoList.carton_length +
        "*" +
        this.infoList.carton_width +
        "*" +
        this.infoList.carton_height
      );
    },
    weight() {
      return this.infoList.gross_weight + "/" + this.infoList.net_weight;
    },
  },
  methods: {
    getLanguageData,
    jump,
    findstate() {
      if (!isLogin()) {
        //没登录提示
        MessageBox.confirm(
          this.$t("common.operate_login_cue"),
          this.$t("common.cue"),
          {
            confirmButtonText: this.$t("common.confirm_text"),
            cancelButtonText: this.$t("common.cancel_text"),
            customClass: "operate-login-cue",
            center: true,
          }
        )
          .then(() => {
            this.$router.push({
              name: "login",
              query: { redirect: "/goodsinfo?id=" + this.id },
            });
          })
          .catch(() => {});
      } 
      else {
        //登录直接加
        this.dialogVisible = true;
      }
    },
    //加入购物车
    gocart() {
      let id = this.id;
      if(this.cartid>0){    
        del({id:this.cartid}).then(res=>{
        if(res.code==0){
          this.$message.success(res.msg)
          this.$store.dispatch('cart/getNum');
          this.getInfo()
        }else{
          this.$message.error(res.msg);
        }
        })
      }else{
        this.$store
        .dispatch("cart/add", { toy_id: id })
        .then((res) => {
          if (res.code == 0) {
            this.getInfo()
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }
     
    },
    getInfo() {
      goodsinfo({ id: this.id }).then((res) => {
        if (res.code == 0) {
          this.infoList = res.data;
          this.cartid=res.data.cart
          this.exhibitor = res.data.exhibitor;
          this.exhibitorid = this.exhibitor.id;
          this.to[1].query={id:this.exhibitor.id}
          this.to[1].name= this.exhibitorname
          this.to[2].name= this.titleinfo
          this.picture = res.data.picture;
          if(res.data.detail){
           this.detail = res.data.detail;
          }else{
            this.loading=false
          }
        
          document.title = this.titleinfo + '-' + this.exhibitorname + '-' +
          this.$t("common.title8");
        }
      });
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    if (!this.id) {
      this.$message.error();
    } else {
      this.getInfo();
    }
  },
};
</script>
<style  scoped  >
.hovercolor:hover {
  color: #3053A3;
}
.crumbcontarner {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #999999;
  opacity: 1;
}
.breadcrumb:hover {
  cursor: pointer;
}
.toyname:hover {
  color: #3053A3;
}
.wrapper-img-size:hover {
  color: #3053A3;
}
.righttextcolor {
  color: #666666;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.background {
  background: #f6f6f6;
  min-height: 100vh;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 14px;
}
a {
  text-decoration: none;
  color: #333;
}
a:hover,
a:visited,
a:link,
a:active {
  color: #333;
}
ul,
li {
  list-style: none;
}
.img-size >>> img {
  max-width: 750px;
  opacity: 1;
  border-radius: 0px;
  display: table;
  margin: 0 auto;
}

.footer-img img {
  max-width: 100%;
}
.info-color {
  color: #f56c6c;

  font-weight: bold;
}
.button-item {
  display: flex;
  margin-top: 30px;
  cursor: pointer;
}
.buttonshowleft{
  width: 150px;
  height: 48px;
  background: #D5DCED;
  opacity: 1;
  border-radius: 4px;
  text-align: center;
  line-height: 48px;
  color: #3053A3;
  margin-right: 16px;
  font-family: MicrosoftYaHei;
}
.buttonclassleft {
  width: 150px;
  height: 48px;
  background: #3053A3;
  opacity: 1;
  border-radius: 4px;
  text-align: center;
  line-height: 48px;
  color: #ffffff;
  margin-right: 16px;
}
.buttonclassright {
  color: #3053A3;
  text-align: center;
  line-height: 48px;
  width: 150px;
  height: 48px;
  background: #D5DCED;
  border: 1px solid #3053A3;
  opacity: 1;
  border-radius: 4px;
}
.content {
  margin: 0 auto;
  width: 1200px;
  padding: 20px;
  background: #ffffff;
  box-sizing: border-box;
}
.container {
  background: #f6f6f6;
  opacity: 1;
  margin: 0 auto;
}
.swiperinfo {
  width: 1200px;
  height: 425px;
  margin: 0 auto;
  /* padding: 20px; */
  position: relative;
  background: #ffffff;
  box-sizing: border-box;
  border: 20px solid #fff;
}
.swiperleft {
  width: 400px;
  height: 300px;
  opacity: 1;
  border-radius: 0px;
}
.rightinfo {
  position: absolute;
  height: 255px;
  font-size: 20px;
  color: #333333;
  top: 20px;
  left: 420px;
}
.title-text {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
}
.content-text {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.content-text li {
  width: 50%;
  margin-top: 18px;
  height: 19px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 19px;
  color: #999999;
  opacity: 1;
}

.wrappertext {
  width: 1200px;
  height: 112px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
  margin: 0 auto;
  margin-top: 14px;
  position: relative;
}
.wrapper-img {
  width: 80px;
  height: 80px;
  opacity: 1;
  border-radius: 0px;
  cursor: pointer;
}
.wrapper-img-size {
  object-fit: contain;
  height: 100%;
  width: 100%;
  margin-top: 16px;
  margin-left: 20px;
}
.toyname {
  font-size: 18px;
  cursor: pointer;
  font-family: MicrosoftYaHei-Bold;
  line-height: 31px;
  color: #3b3b3b;
  opacity: 1;
  position: absolute;
  top: 20px;
  left: 120px;
}
.toy-phone {
  width: 804px;
  display: flex;
  position: absolute;
  top: 66px;
  left: 120px;
  justify-content: space-between;
}
.toy-phone li {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 31px;
  color: #3b3b3b;
  opacity: 1;
}
.hine-info {
  width: 1200px;
  margin: 14px 0 10px;
  text-align: left;
}
.hine {
  font-size: 20px;
  color: #3c3c3c;
  font-weight: bold;
  border-left: 4px solid #3053A3;
  padding-left: 10px;
  line-height: 20px;
}
</style>