<template>
  <div>
    <el-dialog
      :title="enquiryprice"
      :visible.sync="dialogVisible"
      width="600px"
      :close-on-click-modal="false"
      @close-close="handleClose"
    >
      <div class="divider">
        <el-divider></el-divider>
      </div>
      <div>
        <div class="enquiry-item">
          <div class="image-place">
            <el-image
            class="imgsize"
             
              :src="infoList.main_picture"
            ></el-image>
          </div>
          <div class="text-item">
            <h3>
              {{ getLanguageData({ zh: infoList.name, en: infoList.name_en }) }}
            </h3>
            <div>
              <div>
                {{
                  getLanguageData({
                    zh: exhibitor.company_name,
                    en: exhibitor.company_en_name,
                  })
                }}
              </div>
              <div class="article_number">
                {{ $t("goodsinfo.article_number") }}:{{
                  infoList.article_number
                }}
              </div>
            </div>
          </div>
          <div class="inputright">
            <span class="inquiry">{{ $t("goodsinfo.inquiry") }}:</span>
           <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
              <el-form-item prop="pass">
                <el-input-number class="inputlength" :min="0" :step="1" v-model="ruleForm.pass" :controls="false"></el-input-number>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="footerreamrks">
        <span class="remarks"> {{ $t("goodsinfo.remarks") }}:</span>
        <el-input
          maxlength="100"
          class="textborder"
          type="textarea"
          :rows="3"
          v-model="remarks"
        >
        </el-input>
      </div>
      <span slot="footer">
        <div class="dialog-footer">
          <div class="buttonclassright" @click="dialogVisible = false">
            {{ $t("goodsinfo.cancel") }}
          </div>
          <div @click="summitinfo" class="buttonclassleft" plain type="danger">
            {{ $t("goodsinfo.submit") }}
          </div>
        </div>
      </span>
    </el-dialog>
  </div>
</template>
 <script>
</script>
<script>
import { inquiry } from "@/api/goodsinfo.js";
import { getLanguageData } from "@/libs/tools.js";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    infoList: {
      type: Object,
    },
    exhibitor: {
      type: Object,
    },
  },
  components: {},
  data() {
     var validatePass = (rule, value, callback) => {
     if (value < 0) {
      //  message: `${this.$t('goodsinfo.number')}`,
          this.$message({
          message: `${this.$t('goodsinfo.number')}`,
          type: 'error'
        });
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        pass: 0,
      },        
      remarks: "",
      rules: {
        pass: [   { validator: validatePass, trigger: "blur" }   ],
      },
      inquiries: [{}],
    };
  },
  computed: {
     enquiryprice(){
       return this.$t("goodsinfo.enquiryprice")
     },
    dialogVisible: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  watch: {},
  methods: {
    getLanguageData,
    handleClose(done) {            
      this.dialogVisible = false;
    },
    //提交价格
    summitinfo() {
       this.$refs.ruleForm.validate((valid, err) => {
        if (valid) {
          this.dialogVisible = false
          this.inquiries[0].cart_id=0,
          this.inquiries[0].toy_id=this.infoList.id
          this.inquiries[0].inquiry_count= this.ruleForm.pass
          this.inquiries[0].inquiry_content=this.remarks     
          inquiry({inquiries: this.inquiries}).then(res=>{
            if(res.code==0){
              this.$message.success(res.msg)
              this.inquiries[0].inquiry_content=""
             this.ruleForm.pass=''
             this.remarks= ""
            }else{
              this.$message.error(res.msg);
            }
          })  
        }
           });
    },
  },

  created() {},
  mounted() {},
};
</script>
<style  scoped>
.imgsize{
 width: 80px;
  max-height: 80px;
}
.image-place{
  margin-top: -5px;
}
.inquiry {
  display: block;
  margin-top: 23px;
}
.inputright {
  display: flex;
}
.article_number {
  margin-top: 8px;
}
.buttonclassright {
  color: #d81e06;
  text-align: center;
  line-height: 32px;
  width: 100px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #cccccc;
  color: #999999;
  opacity: 1;
  border-radius: 4px;
}
.buttonclassleft {
  width: 100px;
  height: 32px;
  background: #3053A3;
  opacity: 1;
  border-radius: 4px;
  text-align: center;
  line-height: 32px;
  color: #ffffff;
  margin-left: 16px;
}
.dialog-footer {
  display: flex;
  margin-top: 30px;
  cursor: pointer;
  margin-left: 200px;
  text-align: center;
}
.textborder {
  width: 470px;
  height: 36px;
  opacity: 1;
  margin-left: 10px;
  margin-bottom: -8px;
}
.divider {
  margin-top: -42px;
}
.enquiry-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 600px;
}
.text-item {
  flex: 1;
  margin-top: -23px;
  font-size: 12px;
  width: 180px;
  margin-left: 10px;
}
.inputlength {
  width: 100px;
  height: 36px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px;
  margin-right: 74px;
  margin-top: 15px;
  margin-left: 15px;
  font-size: 12px;
}
.remarks {
  font-size: 12px;
  display: block;
  margin-left: 20px;
}
.footerreamrks {
  display: flex;
}
/deep/.inputlength input[type="number"] {
  -moz-appearance: textfield;
}
/deep/.inputlength input::-webkit-inner-spin-button,
/deep/.inputlength input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>